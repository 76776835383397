<template>
  <div style="padding: 20px;height: auto;display: flex;flex-direction: column;align-items: center;justify-content: center;">
    <h1 style="margin-bottom: 50px">聚星星视频一体化</h1>
    <el-form ref="form" :model="preFrom" label-width="100px">
      <el-form-item label="账户ID">
        <div v-for="(item, index) in preFrom.advertiser_ids" :key="index">
          <el-select v-model="item.advertiser_id" filterable placeholder="请选择账户ID">
            <el-option
                v-for="item in accountIds"
                :key="item.id"
                :label="item.accountName"
                :value="item.accountId">
            </el-option>
          </el-select>
        </div>
        <el-button size="small" type="success" @click="addInput">添加账户</el-button>
      </el-form-item>

      <el-form-item label="视频发布时间">
        <el-date-picker
            v-model="createTime"
            type="datetimerange"
            align="right"
            range-separator="至"
            start-placeholder="	创建开始时间"
            end-placeholder="	创建结束时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            :picker-options="pickerOptions"
            @change="getDate"
        />
      </el-form-item>
      <el-form-item label="流量助推状态">
        <el-select v-model="preFrom.selectBoostType" filterable placeholder="请选择账户ID">
          <el-option
              v-for="item in selectBoostTypes"
              :key="item.id"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="display: flex; align-items: center;justify-content: center; margin-top: 50px">
        <el-button type="primary" :loading="loading" @click="onSubmit">立即获取</el-button>
        <el-button type="success" @click="handlerExportRecord">导出记录</el-button>
      </el-form-item>
    </el-form>
    <export-record-dialog
        :export-record.sync="exportRecord"
        :total="total"
        :query-params="queryParams"
    />
  </div>
</template>

<script>
import {parseTime} from "@/utils/untilsMethod";
import {exportList} from "@/api/exportFile";
import {getJXAccountList} from "@/api/jxAccount";
import {exportStarVideoIntegration} from "@/api/jxHandle";
import ExportRecordDialog from '@/components/ExportRecordDialog'

export default {
  components: {
    ExportRecordDialog
  },
  data() {
    return {
      loading: false,
      // 订单导出记录
      exportRecord: {
        open: false,
        title: '星视频一体化导出记录',
        dataList: []
      },
      total:0,
      queryParams: {exportType:21,page:1,size:20},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
        }, {
            text: '最近一年',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      createTime: [],
      selectBoostTypes:[{id:0,value:0, label: "全选"},{id:1,value:1, label: "已投流量助推"},{id:2,value:2, label: "未投流量助推"}],
      accountIds: [],
      preFrom: {
        advertiser_ids: [{ advertiser_id: '' }],
        startTimeMs: undefined, // 创建日期 开始
        endTimeMs: undefined, // 创建日期 结束
        promotionType: 1, // 助推类型  1.星视频 2.星直播 3.星任务(视频)
        page: 1, // 页码
        pageSize: 1000, // 页长
        rankType: false, // true.倒叙
        sortFiled: "", // 排序字段
        keyword: "", // 搜索关键词
        searchKeywordType: 1, // 搜索类型 1.任务名称或ID 2.订单ID 3.达人昵称
        selectTimeType: 1, // 日期类型 1.视频发布时间 2.视频创建时间
        selectBoostType: 0, // 流量助推状态 0.不选 1.已投流量助推 2.未投流量助推
        missionIdList: [], // 任务ID列表
      },
      form: {}
    }
  },
  mounted() {
    this.getAccountList()
  },
  methods: {
    parseTime,
    addInput() {
      // 向数组中添加一个新对象，默认 ad_id 为空字符串
      this.preFrom.advertiser_ids.push({ advertiser_id: '' });
    },
    getAccountList() {
      getJXAccountList().then(response => {
        this.accountIds = response.data
      }).catch(response => {
        this.$message.error(response.msg)
      })
    },
    handlerExportRecord() {
      return exportList(this.queryParams).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
        this.exportRecord.open=true
      })
    },
    async onSubmit() {
      if (!this.preFrom.advertiser_ids) {
        this.$message.error("账户ID不能为空");
        return;
      }
      if (!this.preFrom.startTimeMs || !this.preFrom.endTimeMs) {
        this.$message.error("创建日期不能为空");
        return;
      }
      this.loading = true
      this.getForm()
      exportStarVideoIntegration(this.form).then(response => {
        this.$message.success('[星视频]'+response.msg);
      }).catch(() => {
        this.$message.error('请求失败');
      });
      this.$message.warning('导出中...');
      this.reset();
      this.loading = false;
    },
    reset() {
      this.form = {}
    },
    getForm() {
      this.form = JSON.parse(JSON.stringify(this.preFrom));
    },
    getDate() {
      console.log(this.createTime)
      if (this.createTime) {
        this.preFrom.startTimeMs = this.createTime[0]
        this.preFrom.endTimeMs = this.createTime[1]
      }
      if (this.createTime == null) {
        this.createTime = undefined // 清空开始时间和结束时间
        this.preFrom.startTimeMs = undefined
        this.preFrom.endTimeMs = undefined
      }
    },
  }
}
</script>

